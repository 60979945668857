import BlockContent from '@sanity/block-content-to-react'

const ContactSection = ({ contactDetails }) => {
  return (
    <div className="contact">
      <BlockContent blocks={contactDetails} />
    </div>
  )

  return (
    <div className="contact">
      Email: <a href="mailto:info@helenafoster.com">info@helenafoster.com</a>
      <br />
      <br />
      {/* @TODO: Microformats */}
      Artist Studio <br />
      Bowater Road, <br />
      Woolwich Dockyard,
      <br />
      London,
      <br />
      SE18 5TF
      <br />
      <br />
      *Please note that studio visits are by appointment only.
    </div>
  )
}

export default ContactSection
