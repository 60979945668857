import { useMemo, useState, useEffect } from 'react'
import { shuffle } from 'lodash'
import { SanityImage } from './SanityImage'

const mod = (n, len) => ((n % len) + len) % len

function useDelayUnmount(isMounted, delayTime) {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    let timeoutId
    if (isMounted && !shouldRender) {
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime)
    }
    return () => clearTimeout(timeoutId)
  }, [isMounted, delayTime, shouldRender])
  return shouldRender
}

const HomeImage = (props) => {
  const shouldRenderChild = useDelayUnmount(props.isMounted, 500)

  return (
    shouldRenderChild && (
      <div
        className="home-image"
        style={{
          animation: props.isMounted
            ? `inAnimation 500ms ease-in`
            : `outAnimation 510ms ease-in`,
        }}
      >
        <SanityImage image={props.image} />
      </div>
    )
  )
}

const HomeSection = (props) => {
  const images = useMemo(() => shuffle(props.images), [props.images.works])
  const [currentImageIdx, setCurrentImageIdx] = useState(0)

  useEffect(() => {
    if (props.loop) {
      const interval = setInterval(() => {
        setCurrentImageIdx((n) => mod(n + 1, images.length))
      }, 4000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [setCurrentImageIdx])

  return (
    <div className="home-wrapper" style={`top: ${props.offsetTop}px`}>
      {images.map((image, i) => {
        return <HomeImage isMounted={i === currentImageIdx} image={image} />
      })}
    </div>
  )
}

export default HomeSection
