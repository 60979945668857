import { useState, useRef } from 'react'
import { SanityImage } from './SanityImage'

import BlockContent from '@sanity/block-content-to-react'

const Img = (props) => {
  return (
    <figure className="work-img-wrapper">
      {/* @TODO: alt text */}

      <picture
        style={{
          background: props.meta.palette.dominant.background,
        }}
      >
        <SanityImage image={props} />
      </picture>

      <figcaption className="caption">
        <BlockContent blocks={props.content} />
      </figcaption>
    </figure>
  )
}

const WorksSection = ({ series }) => {
  const [activeWorkIdx, setActiveWork] = useState(0)
  const activeWork = series[activeWorkIdx]

  const containerRef = useRef(null)

  const changeWork = (toIdx) => {
    setActiveWork(toIdx)

    if (containerRef.current) {
      containerRef.current.scrollLeft = 0
    }
  }

  return (
    <div className="work-wrapper">
      <ul className="section-nav">
        {series.map((work, i) => (
          <li
            className={`section-nav__item ${
              activeWork._id === work._id ? 'section-nav__item--active' : ''
            }`}
            onClick={() => changeWork(i)}
          >
            {work.title}

            {work.description && activeWork._id === work._id && (
              <span className="work-msg">{work.description}</span>
            )}
          </li>
        ))}
      </ul>

      <div className="work-image-list" ref={containerRef}>
        {activeWork.works.map((work, i) => (
          <Img {...work} key={work._id} n={i + 1} />
        ))}

        <div className="work-pagination">
          {/* @TODO: Reset scroll position */}

          {activeWorkIdx > 0 ? (
            <span onClick={() => changeWork(activeWorkIdx - 1)}>
              Previous
              <br />
              project {'←'}
            </span>
          ) : (
            <span />
          )}

          {activeWorkIdx < series.length - 1 ? (
            <span onClick={() => changeWork(activeWorkIdx + 1)}>
              Next
              <br />
              project {'→'}
            </span>
          ) : (
            <span />
          )}
        </div>
      </div>
    </div>
  )
}

export default WorksSection
