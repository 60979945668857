import BlockContent from '@sanity/block-content-to-react'

const AboutSection = ({ bio }) => {
  return (
    <div className="about">
      <BlockContent blocks={bio} />
    </div>
  )
}

export default AboutSection
