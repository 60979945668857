import { useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import format from 'date-fns/format'

const JournalSection = ({ journalEntries }) => {
  const [activeEntry, setActiveEntry] = useState(journalEntries[0])

  return (
    <div className="journal-wrapper">
      <ul className="section-nav">
        {journalEntries.map((entry) => (
          <li
            className={`section-nav__item ${
              activeEntry._id === entry._id ? 'section-nav__item--active' : ''
            }`}
            onClick={() => setActiveEntry(entry)}
          >
            {entry.title}{' '}
            {entry.date && (
              <span className="journal-date">
                {format(new Date(entry.date), 'dd.MM.yyyy')}
              </span>
            )}
          </li>
        ))}
      </ul>

      <div className="journal-post">
        {/* @TODO: responsive */}

        {activeEntry.image && activeEntry.image.src && (
          <div className="journal-image">
            <img src={activeEntry.image.src} alt={activeEntry.alt} />
          </div>
        )}

        <div className="copy">
          <BlockContent
            blocks={activeEntry.content}
            projectId={'hdzhiyoo'}
            dataset={'production'}
          />
        </div>
      </div>
    </div>
  )
}

export default JournalSection
