import {
  BrowserRouter,
  StaticRouter,
  Link,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import { useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga'

import HomeSection from './sections/home'
import AboutSection from './sections/about'
import WorksSection from './sections/works'
import JournalSection from './sections/journal'
import ContactSection from './sections/contact'

import BlockContent from '@sanity/block-content-to-react'

import './style'

import sanityData from './assets/data.json'

function CrossIcon(props) {
  return (
    <svg width={66} height={66} viewBox="0 0 66 66" fill="none" {...props}>
      <path fill="currentColor" d="M30 11h6v44h-6z" />
      <path fill="currentColor" d="M55 30v6H11v-6z" />
    </svg>
  )
}

const SectionHeading = ({ active, onClick, section, url }) => {
  return (
    <Link
      className={`section-heading ${active ? 'section-heading--active' : ''}`}
      to={{
        pathname: active ? '/' : url,
      }}
    >
      <h2 id={section.id}>{section.title}</h2>

      <span
        className={`accordion__icon ${active ? 'accordion__icon--rotate' : ''}`}
      >
        <CrossIcon />
      </span>
    </Link>
  )
}

const Main = ({ data }) => {
  const sections = [
    {
      id: 'about',
      title: 'About',
      content: <AboutSection {...data.about} />,
    },
    {
      id: 'work',
      title: 'Works',
      content: <WorksSection series={data.series.order} />,
    },
    {
      id: 'journal',
      title: 'Journal',
      content: <JournalSection journalEntries={data.journalEntries} />,
    },
    {
      id: 'contact',
      title: 'Contact',
      content: <ContactSection {...data.contact} />,
    },
  ]

  let location = useLocation()
  const [gaInitialized, setGaInitialized] = useState(false)
  useEffect(() => {
    if (!gaInitialized) {
      ReactGA.initialize('UA-36165116-1')
      setGaInitialized(true)
    }
    ReactGA.pageview(location.pathname)
  }, [location])

  const sectionOffsetRef = useRef(null)

  const [sectionOffset, setSectionOffset] = useState()

  useEffect(() => {
    if (sectionOffsetRef.current) {
      const offset = sectionOffsetRef.current.getBoundingClientRect().top
      setSectionOffset(offset)
    }
  }, [sectionOffsetRef])

  return (
    <div className="root">
      <main className="main">
        <h1 className="site-heading">
          <Link to="/">
            <BlockContent blocks={data.siteSettings.header} />
          </Link>
        </h1>

        {location.pathname === '/' && (
          <HomeSection {...data.home} offsetTop={sectionOffset} />
        )}

        <div className="sections" ref={sectionOffsetRef}>
          {sections.map((section) => {
            const match = useRouteMatch(`/${section.id}`)
            const active = match !== null

            return (
              <section className="section">
                <SectionHeading
                  active={active}
                  url={`/${section.id}`}
                  section={section}
                />
                <AnimateHeight
                  duration={800}
                  height={active ? 'auto' : 0}
                  animateOpacity
                >
                  <div className="section__content">{section.content}</div>
                </AnimateHeight>
              </section>
            )
          })}
        </div>
      </main>
      <footer>
        <span className="copyright">
          © {new Date().getFullYear()} Helena Foster. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default (props) => {
  if (typeof window !== 'undefined') {
    return (
      <BrowserRouter>
        <Main data={sanityData} />
      </BrowserRouter>
    )
  }

  return (
    <StaticRouter location={props.url}>
      <Main data={sanityData} />
    </StaticRouter>
  )
}
